$(document).ready(function() {
	var $appLogo = $('.app-logo').first();
	$('.login-content.page-card').find('.app-logo').remove();
	$('.login-content.page-card').prepend($appLogo);

	var logoHTML = '<div class="sidebar-logo">' +
		'<img src="/assets/z1n_core/images/z1n_favicon.png" alt="Logo" style="width: 100px; height: auto;">' +
		'</div>';

	// Append the logo to the sidebar
	$('.web-sidebar').prepend(logoHTML);
});